// WebcamCapture.js
import React, { useRef, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import './WebcamCapture.css';
import cosmos from "../../images/cosmos.png";

function WebcamCapture() {
  const webcamRef = useRef(null);
  const [hasPermission, setHasPermission] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [inputText, setInputText] = useState('');
  const [mode, setMode] = useState('description');
  const [generatedImage, setGeneratedImage] = useState('');
  const [showChat, setShowChat] = useState(false);

  useEffect(() => {
    async function checkWebcamPermission() {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        setHasPermission(true);
      } catch (error) {
        console.error('Webcam permission denied:', error);
        setHasPermission(false);
      }
    }
    checkWebcamPermission();
  }, []);

  const generateUniqueId = () => '_' + Math.random().toString(36).substr(2, 9);

  const capture = () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      
      if (mode === 'description') {
        setChatHistory(prevHistory => [
          ...prevHistory,
          { type: 'image', image: imageSrc, text: '', id: generateUniqueId(), mode }
        ]);
        setShowChat(true);
      } else if (mode === 'artist') {
        sendArtisticImage(imageSrc);
      }
    }
  };

  const sendArtisticImage = async (base64Image) => {
    setLoading(true);
    const base64Data = base64Image.split(',')[1];
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/routes/artistic_image`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ image: base64Data, additionalText: inputText })
      });
      const data = await res.json();
      setGeneratedImage(data.message);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const compressImage = (base64Str, maxWidth = 500, maxHeight = 500, quality = 0.7) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = base64Str;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;
  
        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }
  
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
  
        const compressedBase64 = canvas.toDataURL('image/jpeg', quality);
        resolve(compressedBase64);
      };
    });
  };
  
  const sendToBackendMultipleImages = async (imageId) => {
    setLoading(true);
    const compressedImages = await Promise.all(chatHistory.map(async (item) => {
      const compressedImage = await compressImage(item.image);
      return {
        base64Image: compressedImage.split(',')[1],
        prompt: item.text
      };
    }));
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/routes/process_images`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ images: compressedImages })
      });
      const data = await res.json();
      setChatHistory(prevHistory =>
        prevHistory.map(item =>
          item.id === imageId ? { ...item, response: data.message } : item
        )
      );
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e, imageId) => {
    const value = e.target.value;
    setChatHistory(prevHistory =>
      prevHistory.map(item => (item.id === imageId ? { ...item, text: value } : item))
    );
  };

  return (
    <div className="webcam-container" style={{ backgroundImage: `url(${cosmos})` }}>
      <div className="webcam-overlay">
        <header className="webcam-header">
          <h1 className="webcam-title">Webcam GPT</h1>
          <p className="webcam-subtitle">Giving AI eyes to see</p>
        </header>
        
        <div className="main-content">
          <div className="webcam-wrapper">
            {hasPermission ? (
              <Webcam 
                audio={false} 
                ref={webcamRef} 
                screenshotFormat="image/jpeg"
                className="webcam-feed"
              />
            ) : (
              <div className="placeholder-container">
                <p className="permission-message">Please allow access to your webcam to use this feature.</p>
              </div>
            )}
            
            <div className="control-panel">
              <div className="buttons-container">
                <button 
                  className={mode === 'description' ? 'active' : ''} 
                  onClick={() => setMode('description')}
                >
                  Description Mode
                </button>
                <button 
                  className={mode === 'artist' ? 'active' : ''} 
                  onClick={() => setMode('artist')}
                >
                  Artistic Mode
                </button>
              </div>
              <button className="capture-button" onClick={capture} disabled={!hasPermission}>
                Capture
              </button>
            </div>
          </div>
          
          {showChat && mode === 'description' && (
            <div className="chat-wrapper">
              <h3 className="section-title">Chat History</h3>
              <ul className="chat-history">
                {chatHistory.map((item) => (
                  <li key={item.id} className={`chat-item ${item.mode}`}>
                    {item.type === 'image' && (
                      <div className="image-container">
                        <img src={item.image} alt={`Captured ${item.id}`} className="captured-image" />
                        <textarea
                          placeholder="Ask something about this image..."
                          value={item.text}
                          onChange={(e) => handleInputChange(e, item.id)}
                          className="text-input"
                        />
                        <button
                          className="send-button"
                          onClick={() => sendToBackendMultipleImages(item.id)}
                          disabled={loading || !item.text.trim()}
                        >
                          {loading ? 'Processing...' : 'Send'}
                        </button>
                        {item.response && <p className="response">{item.response}</p>}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        
        {mode === 'artist' && (
          <div className="artist-panel">
            <textarea
              placeholder="Enter additional artistic input..."
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              className="text-input artistic-input"
            />
            {generatedImage && (
              <div className="generated-image-container">
                <h3 className="section-title">Generated Artistic Image</h3>
                <img src={generatedImage} alt="Artistic Rendering" className="generated-image" />
              </div>
            )}
          </div>
        )}
        
        {loading && <div className="loading-indicator">Processing your request...</div>}
      </div>
    </div>
  );
}

export default WebcamCapture;