import React, { Suspense, useEffect, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Preload, useGLTF } from '@react-three/drei';
import CanvasLoader from '../../Loader';

const gltfModels = ['saguaro_cacti/saguaro_cacti.glb'];

const SaguaroScans = ({ isMobile }) => {
  const gltfModel = useGLTF(gltfModels[0]);

  return (
    <mesh>
      <hemisphereLight intensity={3} groundColor="blue" />
      <primitive 
        object={gltfModel.scene}
        scale={isMobile ? 2.5 : 3} 
        rotation={[0, Math.PI, 0]}
        position={[0, 0, 0]} // Stationary position
      />
    </mesh>
  );
};

const SaguaroCanvas = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);

    const handleMediaQueryChange = (e) => {
      setIsMobile(e.matches);
    };

    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => mediaQuery.removeEventListener('change', handleMediaQueryChange);
  }, []);

  return (
    <Canvas
      frameloop="always"
      shadows
      camera={{ position: [0, 5, 15], fov: 50 }}
      gl={{ preserveDrawingBuffer: true }}
      style={{ width: '100%', height: '100%' }}
    >
      <Suspense fallback={<CanvasLoader />}>
        <OrbitControls 
          enableZoom={true} 
          autoRotate 
          enablePan 
          minDistance={5}
          maxDistance={20}
          target={[0, 2, 0]} 
        />
        <SaguaroScans isMobile={isMobile} />
      </Suspense>
      <Preload all />
    </Canvas>
  );
};

export default SaguaroCanvas;
