// WebXRWorld.js
import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const WebXRWorld = () => {
  const mountRef = useRef(null);
  const loadedModelRef = useRef(null);  // Use useRef for loadedModel
  const [statusMessage, setStatusMessage] = useState('Ready');
  const [promptInput, setPromptInput] = useState('');
  const [sunColor, setSunColor] = useState('#ffffff');
  const [sunIntensity, setSunIntensity] = useState(1);
  const [sunPosition, setSunPosition] = useState(0);

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);
    
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);
    
    const sunLight = new THREE.DirectionalLight(sunColor, sunIntensity);
    sunLight.position.set(sunPosition * 10, 10, 5);
    scene.add(sunLight);
    
    const gridHelper = new THREE.GridHelper(100, 100, 0xffffff, 0x444444);
    scene.add(gridHelper);
    
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    camera.position.set(0, 1.6, 5);
    
    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };
    animate();
    
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
      if (mountRef.current) {
        mountRef.current.removeChild(renderer.domElement);
      }
    };
  }, [sunColor, sunIntensity, sunPosition]); // React to changes in sun settings

  const handleGenerateModel = async () => {
    if (promptInput.trim() === '') {
      setStatusMessage('Please enter a prompt');
      return;
    }
    generateModel(promptInput);
  };

  const generateModel = async (prompt) => {
    try {
      setStatusMessage('Generating model...');
      const modelData = await interpretPrompt(prompt);
      
      if (!modelData) {
        setStatusMessage('Failed to interpret prompt.');
        return;
      }
      
      if (loadedModelRef.current) {
        mountRef.current.scene.remove(loadedModelRef.current);
      }

      let geometry;
      switch (modelData.shape.toLowerCase()) {
        case 'cube':
          geometry = new THREE.BoxGeometry(modelData.size, modelData.size, modelData.size);
          break;
        case 'sphere':
          geometry = new THREE.SphereGeometry(modelData.size / 2, 32, 32);
          break;
        // Add more shapes here
        default:
          geometry = new THREE.BoxGeometry(1, 1, 1);
          console.warn('Unknown shape: using cube default');
      }
      
      const material = new THREE.MeshPhongMaterial({ color: modelData.color });
      loadedModelRef.current = new THREE.Mesh(geometry, material);
      loadedModelRef.current.position.set(0, 1.6, 0);
      mountRef.current.scene.add(loadedModelRef.current);
      
      setStatusMessage('Model loaded');
    } catch (error) {
      console.error('Model generation failed:', error);
      setStatusMessage('Model generation failed');
    }
  };

  const interpretPrompt = async (prompt) => {
    setStatusMessage('Interpreting prompt...');
    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content: 'Interpret this prompt for a simple 3D model. Return JSON with properties: shape, color, size.'
            },
            { role: 'user', content: prompt }
          ]
        })
      });
      
      if (!response.ok) {
        throw new Error(`OpenAI API error: ${response.status}`);
      }

      const data = await response.json();
      const result = JSON.parse(data.choices[0].message.content);
      
      setStatusMessage('Prompt interpreted');
      return result;
    } catch (error) {
      console.error('Prompt interpretation failed:', error);
      setStatusMessage('Error interpreting prompt');
      return null;
    }
  };

  return (
    <div>
      <div id="interface">
        <input
          type="text"
          placeholder="Enter 3D model prompt (e.g., red cube)"
          value={promptInput}
          onChange={(e) => setPromptInput(e.target.value)}
        />
        <button onClick={handleGenerateModel}>Generate Model</button>
        <button>Load Skybox</button>
        <label>Sun Position</label>
        <input
          type="range"
          min="-1"
          max="1"
          step="0.01"
          value={sunPosition}
          onChange={(e) => setSunPosition(parseFloat(e.target.value))}
        />
        <label>Sun Color</label>
        <input
          type="color"
          value={sunColor}
          onChange={(e) => setSunColor(e.target.value)}
        />
        <label>Sun Intensity</label>
        <input
          type="range"
          min="0"
          max="5"
          step="0.1"
          value={sunIntensity}
          onChange={(e) => setSunIntensity(parseFloat(e.target.value))}
        />
        <button>🎙️ Speak Prompt</button>
        <button>🎙️ Speak Movement Command</button>
      </div>
      <div id="statusText">{statusMessage}</div>
      <div ref={mountRef} />
    </div>
  );
};

export default WebXRWorld;