import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import FileSaver from "file-saver";
import "../styles.css";

const Chat = (props) => {
  const {
    netID,
    conversations,
    setConversations,
    selectedConversation,
    setSelectedConversation,
    messagesInConversation,
    setMessagesInConversation,
    GPT,
    threadID,
    setThreadID,
    theme,
    token,
  } = props;

  const [input, setInput] = useState("");
  const messagesEndRef = useRef(null);
  const location = useLocation();
  const [isBotTyping, setIsBotTyping] = useState(false);
  const [fileNames, setFileNames] = useState();
  const [routeToChatBoolean, setRouteToChatBoolean] = useState(true);
  const [awsCitations, setAwsCitations] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    const currentConversation = conversations
      .filter((conversation) => conversation.id === selectedConversation)
      .sort((a, b) => a.SequenceNumber - b.SequenceNumber);

    let seenUris = new Set();

    currentConversation.forEach((obj) => {
      if (obj.citations) {
        let citationsArray = obj.citations.split(",");
        citationsArray = citationsArray.filter((citation) => {
          if (seenUris.has(citation)) {
            return false;
          } else {
            seenUris.add(citation);
            return true;
          }
        });
        obj.citations = citationsArray.map((citation, index) => {
          return { uri: citation, reference: `[${index + 1}]` };
        });
      }
    });

    setMessagesInConversation(currentConversation);
  }, [selectedConversation]);

  let backend_url = process.env.REACT_APP_BACKEND_URL;
  let login_url = backend_url + "/routes/login";

  const reLogin = async () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/routes/login`;
  };

  useEffect(() => {
    if (token === "null" || token === null) {
      const loginToChat = async () => {
        await reLogin();
      };

      loginToChat();
    }
  }, [netID]);

  // dark theme
  useEffect(() => {
    document.title = "Chat | AI Core";

    if (location.pathname === "/chat") {
      if (theme === "dark") {
        document.documentElement.style.backgroundColor = "#212121";
        document.body.style.backgroundColor = "#212121";
      } else {
        document.documentElement.style.backgroundColor = "white";
        document.body.style.backgroundColor = "white";
      }
    } else {
      document.body.style.backgroundColor = "white";
    }
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [location.pathname, theme]);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messagesInConversation]);

  const sendMessage = async (e) => {
    e.preventDefault();
    if (input.trim() !== "") {
      const newMessages = [
        ...messagesInConversation,
        { text: input, sender: "user" },
      ];
      setMessagesInConversation(newMessages);
      setInput("");
      await new Promise((resolve) => setTimeout(resolve, 500));
      setIsBotTyping(true);

      try {
        // Send the entire message history along with the new message to the backend
        const response = await axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/routes/message-chatbot`,
          method: "POST",
          responseType: "json",
          data: {
            messages: newMessages,
            selectedConversation: selectedConversation,
            selectedModel: GPT,
            threadID: threadID,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        setIsBotTyping(false);

        // Update messages with the response
        if (response.data && response.data.messages) {
          const updatedMessages = [
            ...newMessages,
            {
              text: response.data.messages[0].text[0].text.value,
              sender: "bot",
              selectedModel: response.data.selectedModel,
            },
          ];
          setMessagesInConversation(updatedMessages);
          if (response.data.threadID && response.data.threadID !== threadID) {
            setThreadID(response.data.threadID);
          }
        }
      } catch (error) {
        console.error("Error in sendMessage:", error);
        setIsBotTyping(false);
      }
    }
  };

  const sendMessageToGPT4 = async (e) => {
    e.preventDefault();
    if (input.trim() !== "") {
      const newMessages = [
        ...messagesInConversation,
        { text: input, sender: "user" },
      ];
      setMessagesInConversation(newMessages);
      setInput("");
      await new Promise((resolve) => setTimeout(resolve, 500));
      setIsBotTyping(true);

      try {
        // Send the entire message history along with the new message to the backend
        const response = await axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/routes/message-gpt4`,
          method: "POST",
          responseType: "json",
          data: {
            messages: newMessages,
            selectedConversation: selectedConversation,
            selectedModel: GPT,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        setIsBotTyping(false);

        // Update messages with the response
        if (response.data && response.data.message) {
          const updatedMessages = [
            ...newMessages,
            {
              text: response.data.message,
              sender: "assistant",
              selectedModel: response.data.selectedModel,
            },
          ];
          setMessagesInConversation(updatedMessages);
        }
      } catch (error) {
        console.error("Error in sendMessageToGPT4:", error);
        setIsBotTyping(false);
      }
    }
  };

  const sendMessageToClaude = async (e) => {
    e.preventDefault();
    if (input.trim() !== "") {
      const newMessages = [
        ...messagesInConversation,
        { text: input, sender: "user" },
      ];

      setMessagesInConversation(newMessages);
      setInput("");
      await new Promise((resolve) => setTimeout(resolve, 500));
      setIsBotTyping(true);

      try {
        // Normalize 'bot' sender to 'assistant'
        const normalizedMessages = newMessages.map((msg) =>
          msg.sender === "bot" ? { ...msg, sender: "assistant" } : msg
        );

        // Ensure alternating roles between 'user' and 'assistant'
        const messagesWithAlternatingRoles =
          ensureAlternatingRoles(normalizedMessages);

        const response = await axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/routes/message-claude`,
          method: "POST",
          responseType: "json",
          data: {
            messages: messagesWithAlternatingRoles,
            selectedConversation: selectedConversation,
            selectedModel: GPT,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        setIsBotTyping(false);

        // Update messages with the response from the backend
        if (response.data && response.data.message) {
          const updatedMessages = [
            ...messagesWithAlternatingRoles,
            {
              text: response.data.message,
              sender: "assistant",
              selectedModel: response.data.selectedModel,
            },
          ];

          setMessagesInConversation(updatedMessages);
        }
      } catch (error) {
        console.error("Error in sendMessageToClaude:", error.message);
        setIsBotTyping(false);
      }
    }
  };

  const sendMessageToVaultBot = async (e) => {
    e.preventDefault();
    if (input.trim() !== "") {
      const newMessages = [
        ...messagesInConversation,
        { text: input, sender: "user" },
      ];
      setMessagesInConversation(newMessages);
      setInput("");
      await new Promise((resolve) => setTimeout(resolve, 500));
      setIsBotTyping(true);

      try {
        // Send the entire message history along with the new message to the backend
        const response = await axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/routes/message-vaultBot`,
          method: "POST",
          responseType: "json",
          data: {
            messages: newMessages,
            selectedConversation: selectedConversation,
            selectedModel: GPT,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        const citations = response.data.message.citations;

        const citationsNullCheck = citations?.some(
          (c) => c.retrievedReferences.length === 0
        )
          ? null
          : citations.map((c) => c.retrievedReferences);

        const seenUris = new Set();

        const citationsData = response.data.message.citations
          .map((citation, index) => ({
            text: citation.generatedResponsePart.textResponsePart.text,
            uri: citation.retrievedReferences[0]?.location.s3Location.uri,
            content: citation.retrievedReferences[0]?.content.text,
            reference: `[${index + 1}]`,
          }))
          .filter((citation) => {
            if (seenUris.has(citation.uri)) {
              return false;
            }
            seenUris.add(citation.uri);
            return true;
          });

        // const referenceFileNames = citationsData.map((r) => r.uri)

        setIsBotTyping(false);

        // Update messages with the response
        if (response.data && response.data.message) {
          const updatedMessages = [
            ...newMessages,
            {
              text: response.data.message.output.text,
              sender: "assistant",
              selectedModel: response.data.selectedModel,
              citations: citationsData,
            },
          ];

          setMessagesInConversation(updatedMessages);
        }
      } catch (error) {
        console.error("Error in sendMessageToLlama:", error);
        setIsBotTyping(false);
      }
    }
  };

  const ensureAlternatingRoles = (messages) => {
    return messages.filter((msg, index) => {
      // Allow the first message
      if (index === 0) return true;

      // Check if the current message's sender is the same as the previous one
      if (msg.sender === messages[index - 1].sender) {
        // If both are 'user', skip the current message
        if (msg.sender === "user") {
          return false;
        }
      }

      return true;
    });
  };

  const sendMessageToGemini = async (e) => {
    e.preventDefault();
    if (input.trim() !== "") {
      const newMessages = [
        ...messagesInConversation,
        { text: input, sender: "user" },
      ];
      setMessagesInConversation(newMessages);
      setInput("");
      await new Promise((resolve) => setTimeout(resolve, 500));
      setIsBotTyping(true);

      try {
        // Send the entire message history along with the new message to the backend
        const response = await axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/routes/message-gemini`,
          method: "POST",
          responseType: "json",
          data: {
            messages: newMessages,
            selectedConversation: selectedConversation,
            selectedModel: GPT,
            threadID: threadID,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        setIsBotTyping(false);

        // Update messages with the response
        if (response.data && response.data.message) {
          const updatedMessages = [
            ...newMessages,
            {
              text: response.data.message,
              sender: "bot",
              selectedModel: response.data.selectedModel,
            },
          ];

          setMessagesInConversation(updatedMessages);

          if (response.data.threadID && response.data.threadID !== threadID) {
            setThreadID(response.data.threadID);
          }
        }
      } catch (error) {
        console.error("Error in sendMessage:", error);
        setIsBotTyping(false);
      }
    }
  };

  const sendMessageToLlama = async (e) => {
    e.preventDefault();
    if (input.trim() !== "") {
      const newMessages = [
        ...messagesInConversation,
        { text: input, sender: "user" },
      ];
      setMessagesInConversation(newMessages);
      setInput("");
      await new Promise((resolve) => setTimeout(resolve, 500));
      setIsBotTyping(true);

      try {
        // Send the entire message history along with the new message to the backend
        const response = await axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/routes/message-llama`,
          method: "POST",
          responseType: "json",
          data: {
            messages: newMessages,
            selectedConversation: selectedConversation,
            selectedModel: GPT,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        setIsBotTyping(false);

        // Update messages with the response
        if (response.data && response.data.message) {
          const updatedMessages = [
            ...newMessages,
            {
              text: response.data.message,
              sender: "assistant",
              selectedModel: response.data.selectedModel,
            },
          ];
          setMessagesInConversation(updatedMessages);
        }
      } catch (error) {
        console.error("Error in sendMessageToLlama:", error);
        setIsBotTyping(false);
      }
    }
  };

  const sendMessageToAicoreAgent = async (e) => {
    e.preventDefault();
    if (input.trim() !== "") {
      const newMessages = [
        ...messagesInConversation,
        { text: input, sender: "user" },
      ];
      setMessagesInConversation(newMessages);
      setInput("");
      await new Promise((resolve) => setTimeout(resolve, 500));
      setIsBotTyping(true);

      try {
        // Send the entire message history along with the new message to the backend
        const response = await axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/routes/message-aicore-agent`,
          method: "POST",
          responseType: "json",
          data: {
            problem: newMessages,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        setIsBotTyping(false);
        console.log(response.data);
        
        // Update messages with the response
        if (response.data && response.data.message) {
          let updatedMessages = newMessages;
          let counter = 0;
          response.data.message.map((message) => {
            counter += 1;
            console.log(counter);
            if (message.content !== ""){
              setTimeout(() => {
                updatedMessages = [
                  ...updatedMessages, 
                  {
                    text: message.content,
                    sender: "bot" + message.name,
                    selectedModel: "AI Core Agents",
                  }
                ] 
                setMessagesInConversation(updatedMessages);
                scrollToBottom();
              }, counter * 3000);
            }
          });

          // console.log(updatedMessages);
          // setMessagesInConversation(updatedMessages);
        }
      } catch (error) {
        console.error("Error in sendMessageToAicoreAgent:", error);
        setIsBotTyping(false);
      }
    }
  };
  
  const sendMessageToMistral = async (e) => {
    e.preventDefault();
    if (input.trim() !== "") {
      const newMessages = [
        ...messagesInConversation,
        { text: input, sender: "user" },
      ];
      setMessagesInConversation(newMessages);
      setInput("");
      await new Promise((resolve) => setTimeout(resolve, 500));
      setIsBotTyping(true);

      try {
        // Send the entire message history along with the new message to the backend
        const response = await axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/routes/message-mistral`,
          method: "POST",
          responseType: "json",
          data: {
            messages: newMessages,
            selectedConversation: selectedConversation,
            selectedModel: GPT,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        setIsBotTyping(false);

        // Update messages with the response
        if (response.data && response.data.message) {
          const updatedMessages = [
            ...newMessages,
            {
              text: response.data.message,
              sender: "bot",
              selectedModel: response.data.selectedModel,
            },
          ];
          setMessagesInConversation(updatedMessages);
        }
      } catch (error) {
        console.error("Error in sendMessageToMistral:", error);
        setIsBotTyping(false);
      }
    }
  };

  const sendMessageToGroq = async (e) => {
    e.preventDefault();
    if (input.trim() !== "") {
      const newMessages = [
        ...messagesInConversation,
        { text: input, sender: "user" },
      ];
      setMessagesInConversation(newMessages);
      setInput("");
      await new Promise((resolve) => setTimeout(resolve, 500));
      setIsBotTyping(true);

      try {
        // Send the entire message history along with the new message to the backend
        const response = await axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/routes/message-groq`,
          method: "POST",
          responseType: "json",
          data: {
            messages: newMessages,
            selectedConversation: selectedConversation,
            selectedModel: GPT,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        setIsBotTyping(false);

        // Update messages with the response
        if (response.data && response.data.message) {
          const updatedMessages = [
            ...newMessages,
            {
              text: response.data.message,
              sender: "bot",
              selectedModel: response.data.selectedModel,
            },
          ];
          setMessagesInConversation(updatedMessages);
        }
      } catch (error) {
        console.error("Error in sendMessageToGrok:", error);
        setIsBotTyping(false);
      }
    }
  };

  const sendMessageToStableDiffusion = async (e) => {
    e.preventDefault();
    if (input.trim() !== "") {
      const newMessages = [
        ...messagesInConversation,
        { text: input, sender: "user" },
      ];
      setMessagesInConversation(newMessages);
      setInput("");
      await new Promise((resolve) => setTimeout(resolve, 500));
      setIsBotTyping(true);

      try {
        // Send the entire message history along with the new message to the backend
        const response = await axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/routes/message-stable-diffusion`,
          method: "POST",
          responseType: "json",
          data: {
            messages: newMessages,
            selectedConversation: selectedConversation,
            selectedModel: GPT,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        setIsBotTyping(false);

        // Convert base64 string to a binary string
        const byteCharacters = atob(response.data.message);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Create a Blob from the binary string
        const blob = new Blob([byteArray], { type: "image/png" }); // Assuming the image is a PNG

        // Create an object URL from the Blob
        const objectURL = URL.createObjectURL(blob);

        // Update messages with the response
        if (response.data && response.data.message) {
          const updatedMessages = [
            ...newMessages,
            {
              text: objectURL,
              sender: "bot",
              selectedModel: response.data.selectedModel,
            },
          ];
          setMessagesInConversation(updatedMessages);
        }
      } catch (error) {
        console.error("Error in sendMessageToStableDiffusion:", error);
        setIsBotTyping(false);
      }
    }
  };

  const sendMessageToChipbot = async (e) => {
    e.preventDefault();
    if (input.trim() !== "") {
      const newMessages = [
        ...messagesInConversation,
        { text: input, sender: "user" },
      ];
      setMessagesInConversation(newMessages);
      setInput("");
      await new Promise((resolve) => setTimeout(resolve, 500));
      setIsBotTyping(true);

      try {
        // Send the entire message history along with the new message to the backend
        const response = await axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/routes/message-chipbot`,
          method: "POST",
          responseType: "json",
          data: {
            messages: newMessages,
            selectedConversation: selectedConversation,
            selectedModel: GPT,
            threadID: threadID,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        setIsBotTyping(false);
        setFileNames(response.data.fileId.filename);

        // Update messages with the response
        if (response.data && response.data.messages) {
          const updatedMessages = [
            ...newMessages,
            {
              text: response.data.messages[0].text,
              sender: "bot",
              selectedModel: response.data.selectedModel,
            },
          ];
          setMessagesInConversation(updatedMessages);

          if (response.data.threadID && response.data.threadID !== threadID) {
            setThreadID(response.data.threadID);
          }
        }
      } catch (error) {
        console.error("Error in sendMessage:", error);
        setIsBotTyping(false);
      }
    }
  };

  const handleImageDownload = async (imageUrl) => {
    const filename = imageUrl.split("/").pop(); // Extract filename (adjust if needed)
    // saveAs(imageUrl, filename);
    FileSaver.saveAs(imageUrl, filename);
  };

  const handleMessageSend = (event) => {
    if (GPT === "Claude (Sonnet)") {
      sendMessageToClaude(event);
    } else if (GPT === "Gemini 1.5 Pro") {
      sendMessageToGemini(event);
    } else if (GPT === "Groq") {
      sendMessageToGroq(event);
    } else if (GPT === "Llama 3") {
      sendMessageToLlama(event);
    } else if (GPT === "Mistral") {
      sendMessageToMistral(event);
    } else if (GPT === "Stable Diffusion") {
      sendMessageToStableDiffusion(event);
    } else if (GPT === "GPT-4o") {
      sendMessageToGPT4(event);
    } else if (GPT === "GPT-4o + Chip Bot") {
      sendMessageToChipbot(event);
    } else if (GPT === "Claude + Vault Bot") {
      sendMessageToVaultBot(event);
    } else if (GPT === "AI Core Agents") {
      sendMessageToAicoreAgent(event);
    } else {
      sendMessage(event);
    }
  };

  const getImagePath = (selectedModel) => {
    switch (selectedModel) {
      case "Gating Network":
        return require("../images/gating.png");
      case "Claude (Sonnet)":
        return require("../images/claude.jpg");
      case "Llama 3":
        return require("../images/llama.png");
      case "Gemini 1.5 Pro":
        return require("../images/gemini.jpg");
      case "Mistral":
        return require("../images/mistral.jpg");
      case "Wildcat News":
        return require("../images/wildcat.jpg");
      case "Expert Comedian":
        return require("../images/comedian.png");
      case "Groq":
        return require("../images/groq.jpg");
      case "Claude + Vault Bot":
        return require("../images/claude.jpg");
      case "Stable Diffusion":
        return require("../images/stability.jpg");
      case "GPT-4o":
        return require("../images/gpt4.png");
      default:
        return require("../images/default.webp");
    }
  };

  return (
    <>
      {selectedConversation ? (
        <div style={{ paddingTop: "30px", marginLeft: "260px" }}>
          <div className="chat_container">
            <div className="messages_container">
              {messagesInConversation
                ?.filter((message) => message.text !== "NULL")
                .map((message, index) => (
                  <div key={index}>
                    <div
                      className="individual_message_container"
                      style={{
                        justifyContent:
                          message?.sender === "bot" ||
                          message?.sender === "assistant"
                            ? "left"
                            : "",
                      }}
                    >


                      <div>
                        {message?.sender.includes("bot") ||
                        message?.sender.includes("assistant") ? (
                          <img
                            className="bot-profile-pic"
                            src={getImagePath(message?.selectedModel)}
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      
                      <div className={`individual_message_subcontainer`}>
                        {/* added replier name for chatbot. */} 
                        <div>
                          {message?.sender.includes("Reviewer") ||
                          message?.sender.includes("Illustrator") ||
                          message?.sender.includes("Writer") ? (
                            <p className="replier_name">{message?.sender.slice(3)}</p>
                          ) : (
                            ""
                          )}
                        </div>

                        <div
                          className={`individual_message_bubble ${
                            message?.sender === "user" ? "message_sender" : ""
                          } ${
                            message.text.startsWith("https://oaidalleapiprodscus")
                              ? "is_image"
                              : ""
                          }`}
                        >
                          {message.text.startsWith(
                            "https://oaidalleapiprodscus"
                          ) ? (
                            <div className="image_with_button">
                              <img
                                src={message.text}
                                alt="AI Generated"
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  borderRadius: "8px",
                                }}
                              />
                              <>
                                <button
                                  className="download_button_outlined"
                                  onClick={() =>
                                    handleImageDownload(message.text)
                                  }
                                >
                                  <i
                                    className="material-symbols-outlined invert"
                                    style={{ color: "var(--chat000)" }}
                                  >
                                    download
                                  </i>
                                </button>
                              </>
                            </div>
                          ) : message.selectedModel === "Stable Diffusion" ? (
                            <img
                              style={{
                                maxWidth: "100%",
                                height: "auto",
                                borderRadius: "8px",
                              }}
                              src={message.text}
                            ></img>
                          ) : message.selectedModel === "Claude + Vault Bot" &&
                            (message.sender === "assistant" ||
                              message.sender === "bot") ? (
                            <div className="individual_message_bot">
                              {
                                <div className="individual_message_bot">
                                  {message.text}
                                </div>
                              }
                              <div>
                                {message &&
                                  message.citations &&
                                  message.citations?.map((citation, index) => (
                                    <div
                                      key={index}
                                      className="citation-container"
                                    >
                                      <span className="citation-reference">
                                        {citation.reference}
                                        <div className="citation-hover-content">
                                          {citation.uri}
                                          {/* <a style={{overflowWrap: "break-word"}}href={citation.uri} target="_blank" rel="noopener noreferrer">{citation.uri}</a> */}
                                        </div>
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          ) : (
                            <div className="individual_message_bot">
                              {message.text}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              {isBotTyping && (
                <div
                  className="individual_message_container"
                  style={{ justifyContent: "left" }}
                >
                  <img
                    className="bot-profile-pic"
                    src={
                      GPT === "Gating Network"
                        ? require("../images/gating.png")
                        : GPT === "Claude (Sonnet)"
                        ? require("../images/claude.jpg")
                        : GPT === "Llama 3"
                        ? require("../images/llama.png")
                        : GPT === "Mistral"
                        ? require("../images/mistral.jpg")
                        : GPT === "Wildcat News"
                        ? require("../images/wildcat.jpg")
                        : GPT === "Expert Comedian"
                        ? require("../images/comedian.png")
                        : GPT === "GPT-4o"
                        ? require("../images/gpt4.png")
                        : GPT === "Gemini 1.5 Pro"
                        ? require("../images/gemini.jpg")
                        : GPT === "Stable Diffusion"
                        ? require("../images/stability.jpg")
                        : GPT === "Groq"
                        ? require("../images/groq.jpg")
                        : GPT === "Claude + Vault Bot"
                        ? require("../images/claude.jpg")
                        : require("../images/default.webp")
                    }
                    alt=""
                  />

                  <div className="individual_message_bubble">
                    <div className="typing-animation">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef}></div>
            </div>
          </div>
          <br />

          <div className="send_message_bar">
            {/*<button type="button" className="attach_button"><i className="material-symbols-outlined" style={{ color: "white" }}>attach_file</i></button>*/}
            <div style={{ marginLeft: "260px" }}>
              <TextareaAutosize
                name="input"
                type="text"
                className="form_chatbox chatpage"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder={`Message ${GPT}...`}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    handleMessageSend(e);
                  }
                }}
              />
              <button
                type="submit"
                className="send_message_button"
                disabled={!input}
                onClick={handleMessageSend}
              >
                <i
                  className="material-symbols-outlined inline-icon"
                  style={{ color: "var(--chat900)" }}
                >
                  arrow_upward
                </i>
              </button>
              <br />
              <div
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontSize: "12px",
                  textAlign: "center",
                  color: "var(--chat000)",
                  opacity: "0.6",
                }}
              >
                Powered by AI Core at the University of Arizona.
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              marginLeft: "260px",
              height: "80vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ textAlign: "center", color: "var(--chat000)" }}>
              Welcome!
              <br />
              <br />
              {!netID || netID === "null"
                ? "Log in with NetID or passcode to get started."
                : "Create a chat or select a conversation to get started."}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Chat;
