// ai_core_homepage.jsx
import React, { useEffect, useState } from "react";
import axios from "axios";
import AOS from 'aos';
import 'aos/dist/aos.css';
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import "./Home2.css";
import '../fonts.css';
import aispeaking from '../images/AI_Core_Workshop.webp';
import theprompt from '../images/ThePromptMay24.webp';
import chatbotIcon from '../images/chatbot-icon.png';
import lundgrenLab from '../images/lundgren-lab.png';
import communityImage from '../images/community.jpg';

// UA Colors
const colors = {
  midnight: "#001C48",  // Primary background
  river: "#007D84",     // Secondary background (replaced Azurite)
  sky: "#70B865",       // Accent color
  red: "#AB0520",       // Call-to-action
  white: "#FFFFFF",
  lightGray: "#EEEEEE",
  oasis: "#A8C6DA",     // Subtle accent
  copper: "#8B3D0A"     // Alternative accent
};

const FeatureBlock = ({ title, description, image, link, index }) => {
    // All blocks use midnight color now
    const bgColor = colors.midnight;
    
    // Horizontal line after title - use sky color
    const lineColor = colors.sky;
    
    return (
      <div className="feature-block-full" data-aos="fade-up" style={{
        backgroundColor: bgColor,
        color: colors.white,
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
        border: `1px solid ${colors.oasis}30`, // Semi-transparent border
        marginBottom: "30px",
        padding: "30px 20px" // Reduced padding for better mobile experience
      }}>
        <div className="feature-horizontal">
          {image && <img src={image} alt={title} className="feature-image-left" />}
          <div className="feature-text-side" style={{ padding: "24px" }}>
            <h3 className="feature-title lastica-font" style={{ 
              color: colors.white, 
              fontSize: "24px",
              marginBottom: "16px"
            }}>{title}</h3>
            
            {/* Horizontal line under title */}
            <div style={{
              width: "100px",
              height: "2px",
              backgroundColor: lineColor,
              marginBottom: "16px"
            }}></div>
            
            <p className="feature-description" style={{ 
              color: colors.lightGray,
              lineHeight: "1.6",
              marginBottom: "20px"
            }}>{description}</p>
            
            {link && (
              <a href={link} target="_blank" rel="noopener noreferrer" className="feature-link" style={{
                display: "inline-block",
                padding: "12px 24px",
                backgroundColor: colors.red,
                color: colors.white,
                textDecoration: "none",
                borderRadius: "4px",
                fontWeight: "500",
                transition: "all 0.3s ease",
                border: "none",
                boxShadow: "0 2px 4px rgba(0,0,0,0.2)"
              }}>
                Visit Website
              </a>
            )}
          </div>
        </div>
      </div>
    );
  };
  
const Home = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
 
  const features = [
    {
      title: "AI Core Chatbot",
      description: "Check out our Chatbot — featuring Claude 3.7 Sonnet, LLaMA 3.3, OpenAI and Gemini Flash 2.0 models all available under one platform.",
      image: chatbotIcon,
      link: "https://chat.aicore.arizona.edu/"
    },
    {
      title: "Digital Twin of the Lundgren Science Retail Lab",
      description: "Explore our digital simulation of the University of Arizona Lundgren Science Retail Lab — where physical meets virtual for AI-driven retail analytics.",
      image: lundgrenLab,
    },
    {
      title: "WebXR World",
      description: "Generate 3D models in a Skybox environment",
      image: communityImage,
    },
  ];
  
  const [email, setEmail] = useState("");
  const [joinedMailingList, setJoinedMailingList] = useState("");
  
  const handleMailingList = async (e) => {
    e.preventDefault();
    if (!email) return;
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/routes/join-mailing-list`, { email });
      setJoinedMailingList("Joined");
      setEmail("");
    } catch {
      setJoinedMailingList("Error");
    }
  };
  
  return (
    <div className="home-container" style={{ 
      backgroundColor: colors.midnight,
      color: colors.white,
      paddingBottom: "60px",
      backgroundImage: `linear-gradient(160deg, ${colors.midnight} 60%, ${colors.river} 100%)`, // Keep the gradient
    }}>
      {/* Hero Image + AI CORE */}
      <div className="frontpage" style={{
        position: "relative" // Ensure position relative for absolute children
      }}>
        {/* Background overlay with increased transparency */}
        <div style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "linear-gradient(to bottom, rgba(0,28,72,0.4) 0%, rgba(0,28,72,0.6) 100%)",
          zIndex: 1
        }}></div>
        
        {/* Add subtle diagonal accent */}
        <div style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          background: `linear-gradient(135deg, transparent 85%, ${colors.sky}50 85%)`, // Semi-transparent accent
          zIndex: 2
        }}></div>
        
        {/* WebcamGPT Content Container - Mobile Responsive */}
        <div style={{
          position: "absolute",
          top: "15%", // Moved up slightly
          left: "0",
          width: "100%",
          zIndex: 3,
          padding: "0 20px",
          textAlign: "center"
        }} data-aos="fade-up">
          <div style={{
            maxWidth: "600px",
            margin: "0 auto",
            backgroundColor: "rgba(0,28,72,0.7)",
            padding: "25px",
            borderRadius: "8px"
          }}>
            <h2 className="lastica-font" style={{ 
              fontSize: "clamp(28px, 5vw, 42px)", // Responsive font size
              color: colors.white,
              marginBottom: "15px",
              textShadow: "0 2px 10px rgba(0,0,0,0.5)"
            }}>WebcamGPT</h2>
            <p style={{ 
              fontSize: "clamp(16px, 3vw, 20px)", // Responsive font size
              lineHeight: "1.6",
              marginBottom: "25px",
              color: colors.lightGray,
              textShadow: "0 1px 5px rgba(0,0,0,0.5)"
            }}>An insight into how AI understands the world.</p>
            <button 
              onClick={() => navigate('/webcam')} 
              className="lastica-font"
              style={{
                padding: "14px 28px",
                backgroundColor: colors.red,
                color: colors.white,
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                fontWeight: "500",
                fontSize: "16px",
                transition: "all 0.2s ease",
                minWidth: "180px"
              }}
            >
              Try WebcamGPT
            </button>
          </div>
        </div>
        
        {/* AI CORE Title - Explicitly positioned at bottom right */}
        <div style={{
          position: "absolute",
          bottom: "40px", // Distance from bottom
          right: "40px", // Distance from right
          zIndex: 4, // Above other elements
          textAlign: "right"
        }}>
          <div className="title_box_subtitle lastica-font" style={{ 
            fontSize: "clamp(60px, 10vw, 130px)", // Responsive font size
            color: colors.white,
            textShadow: "0 2px 30px rgba(0,0,0,0.7)",
            lineHeight: "1"
          }}>AI CORE</div>
        </div>
      </div>
      
      {/* Features Section (Animated) */}
      <div id="features" className="features-section" style={{ 
        backgroundColor: "transparent",
        padding: "40px 20px",
        maxWidth: "1200px",
        margin: "0 auto"
      }}>
        <h2 style={{ 
          color: colors.white, 
          textAlign: "center", 
          marginBottom: "40px",
          fontSize: "32px",
          position: "relative",
          paddingBottom: "15px"
        }}>
          Featured Projects
          <span style={{ 
            display: "block", 
            width: "80px", 
            height: "3px", 
            backgroundColor: colors.sky, 
            margin: "15px auto 0"
          }}></span>
        </h2>
        
        {features.map((feature, index) => (
          <FeatureBlock key={index} {...feature} index={index} />
        ))}
      </div>
      
      {/* Mailing List Section - KEEPING RIVER COLOR */}
      <div className="mailing-list-section" style={{ 
        backgroundColor: colors.river, // Keep the River color
        color: colors.white,
        padding: "40px 30px",
        borderRadius: "8px",
        maxWidth: "1000px",
        margin: "20px auto 0",
        boxShadow: "0 4px 20px rgba(0,0,0,0.25)",
        position: "relative",
        overflow: "hidden"
      }}>
        {/* Accent corner */}
        <div style={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "150px",
          height: "150px",
          backgroundColor: colors.sky,
          opacity: 0.2,
          clipPath: "polygon(100% 0, 0 0, 100% 100%)"
        }}></div>
        
        <h2 className="lastica-font" style={{ 
          color: colors.white, 
          fontSize: "28px",
          marginBottom: "15px",
          position: "relative",
          textAlign: "center" // Center for mobile
        }}>Join Our Mailing List</h2>
        
        <p style={{ 
          color: colors.lightGray,
          marginBottom: "25px",
          maxWidth: "600px",
          lineHeight: "1.6",
          margin: "0 auto 25px auto", // Center the text
          textAlign: "center" // Center for mobile
        }}>Stay updated with AI Core and receive our monthly newsletter, The Prompt!</p>
        
        <form onSubmit={handleMailingList} className="mailing-list-form" style={{
          display: "flex",
          flexDirection: "row",
          gap: "15px",
          maxWidth: "600px",
          margin: "0 auto", // Center the form
          flexWrap: "wrap", // Wrap on mobile
          justifyContent: "center" // Center on wrap
        }}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              padding: "14px 16px",
              borderRadius: "4px",
              border: "none",
            //   backgroundColor: colors.midnight,
              color: colors.white,
              flexGrow: 1,
              fontSize: "16px",
              minWidth: "250px" // Ensure good size on mobile
            }}
          />
          <button type="submit" className="lastica-font" style={{
            padding: "14px 28px",
            backgroundColor: colors.red,
            color: colors.white,
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            fontWeight: "500",
            fontSize: "16px",
            transition: "all 0.2s ease"
          }}>Join the list</button>
        </form>
        
        {joinedMailingList && (
          <div className="mailing-list-feedback" style={{ 
            color: joinedMailingList === "Joined" ? colors.sky : colors.red,
            marginTop: "15px",
            fontWeight: "500",
            textAlign: "center" // Center for mobile
          }}>
            {joinedMailingList === "Joined" ? "Thanks for joining our mailing list :)" : "Error. Try again later."}
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;