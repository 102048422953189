// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Lastica.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/fonts.css */
@font-face {
    font-family: 'Lastica';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  .lastica-font {
    font-family: 'Lastica', sans-serif;
  }`, "",{"version":3,"sources":["webpack://./src/fonts.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,sBAAsB;IACtB,+DAAkD;IAClD,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,kCAAkC;EACpC","sourcesContent":["/* src/fonts.css */\n@font-face {\n    font-family: 'Lastica';\n    src: url('./fonts/Lastica.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n    font-display: swap;\n  }\n  \n  .lastica-font {\n    font-family: 'Lastica', sans-serif;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
