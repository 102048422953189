import React, { useState, useEffect } from "react";
import axios from "axios";
import prompt1 from "../prompt/The_Prompt_Issue_1_-_January_2024.pdf";
import prompt2 from "../prompt/The_Prompt_Issue_2_-_February_2024.pdf";
import prompt3 from "../prompt/The_Prompt_Issue_3_-_March_2024.pdf";
import prompt4 from "../prompt/The_Prompt_-_Issue_4.pdf";
import prompt5 from "../prompt/The_Prompt_-_Issue_5.pdf";
import prompt11 from "../prompt/The_Prompt_Issue_11.pdf";
import titleImage from "../images/paper.jpg";
import "./ThePrompt.css";

const ThePrompt = () => {
  useEffect(() => {
    document.title = "The Prompt | AI Core";
  }, []);

  const [email, setEmail] = useState("");
  const [joinedMailingList, setJoinedMailingList] = useState("");

  const handleMailingList = async (e) => {
    e.preventDefault();
    if (!email) return;
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/routes/join-mailing-list`,
        { email }
      );
      setJoinedMailingList("joined");
      alert("Successfully added to mailing list!");
      setEmail("");
    } catch (error) {
      setJoinedMailingList("Error");
    }
  };

  return (
    <div className="the-prompt-container">
      {/* Animated Background Section */}
      <div
        className="animated_bg"
        style={{ backgroundImage: `url(${titleImage})` }}
      >
        <div className="animated_title">The Prompt</div>
      </div>

      {/* Full-width "Stay Informed" Section */}
      <div className="full-width-container">
        <div className="content_block">
          <div className="content">
            <div className="title_box_subtitle-prompt">
              Stay Informed
            </div>
            <div className="bubble_text">
              Enter your email and we'll send new issues straight to your inbox each month!
            </div>
            <form onSubmit={handleMailingList} className="subscribe-form">
              <input
                type="email"
                className="form"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                aria-label="Email address"
              />
              <button
                type="submit"
                className="button"
              >
                Get access
              </button>
            </form>
            {joinedMailingList !== "" && (
              <div className="feedback-message">
                {joinedMailingList === "joined"
                  ? "Thanks for joining our mailing list :)"
                  : "Internal server error."}
              </div>
            )}
            {joinedMailingList === "Error" && (
              <div className="feedback-message error">
                {`(${process.env.REACT_APP_BACKEND_URL}/routes/join-mailing-list did not return a response)`}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Recent Newsletters Section */}
      <div className="content_block newsletter-section">
        <div className="content">
          <div className="title_box_subtitle-prompt">
            Recent Newsletters
          </div>

          <div className="newsletter-grid">
            {/* Newsletter 1 */}
            <div className="newsletter-card">
              <div className="card-image-container">
                <a href={prompt11} target="_blank" rel="noopener noreferrer">
                  <img
                    className="prompt_cover"
                    src={require("../images/ThePromptFeb25.png")}
                    alt="February 2025 newsletter cover"
                  />
                </a>
              </div>
              <div className="card-content">
                <div className="card-title">AGI on Local compute</div>
                <div className="card-tag">February 2025</div>
                <div className="card-description">AGI on Local compute</div>
                <div className="card-actions">
                  <a
                    href={prompt11}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button"
                  >
                    <i className="material-symbols-outlined inline-icon">
                      picture_as_pdf
                    </i>{" "}
                    View
                  </a>
                </div>
              </div>
            </div>

            {/* Newsletter 2 */}
            <div className="newsletter-card">
              <div className="card-image-container">
                <a href={prompt5} target="_blank" rel="noopener noreferrer">
                  <img
                    className="prompt_cover"
                    src={require("../images/ThePromptMay24.webp")}
                    alt="May 2024 newsletter cover"
                  />
                </a>
              </div>
              <div className="card-content">
                <div className="card-title">Agentic Workflows</div>
                <div className="card-tag">May 2024</div>
                <div className="card-description">
                  Explore the world of Agentic Workflows.
                </div>
                <div className="card-actions">
                  <a
                    href={prompt5}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button"
                  >
                    <i className="material-symbols-outlined inline-icon">
                      picture_as_pdf
                    </i>{" "}
                    View
                  </a>
                </div>
              </div>
            </div>

            {/* Newsletter 3 */}
            <div className="newsletter-card">
              <div className="card-image-container">
                <a href={prompt4} target="_blank" rel="noopener noreferrer">
                  <img
                    className="prompt_cover"
                    src={require("../prompt/prompt4.png")}
                    alt="April 2024 newsletter cover"
                  />
                </a>
              </div>
              <div className="card-content">
                <div className="card-title">The Road to AGI</div>
                <div className="card-tag">April 2024</div>
                <div className="card-description">
                  Follow major steps taken towards building artificial general intelligence.
                </div>
                <div className="card-actions">
                  <a
                    href={prompt4}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button"
                  >
                    <i className="material-symbols-outlined inline-icon">
                      picture_as_pdf
                    </i>{" "}
                    View
                  </a>
                </div>
              </div>
            </div>

            {/* Newsletter 4 */}
            <div className="newsletter-card">
              <div className="card-image-container">
                <a href={prompt3} target="_blank" rel="noopener noreferrer">
                  <img
                    className="prompt_cover"
                    src={require("../prompt/prompt3.png")}
                    alt="March 2024 newsletter cover"
                  />
                </a>
              </div>
              <div className="card-content">
                <div className="card-title">AI-Generated Avatars</div>
                <div className="card-tag">March 2024</div>
                <div className="card-description">
                  Discover the leading edge of AI avatar technology.
                </div>
                <div className="card-actions">
                  <a
                    href={prompt3}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button"
                  >
                    <i className="material-symbols-outlined inline-icon">
                      picture_as_pdf
                    </i>{" "}
                    View
                  </a>
                </div>
              </div>
            </div>

            {/* Newsletter 5 */}
            <div className="newsletter-card">
              <div className="card-image-container">
                <a href={prompt2} target="_blank" rel="noopener noreferrer">
                  <img
                    className="prompt_cover"
                    src={require("../prompt/prompt2.png")}
                    alt="February 2024 newsletter cover"
                  />
                </a>
              </div>
              <div className="card-content">
                <div className="card-title">GPTs & Business</div>
                <div className="card-tag">February 2024</div>
                <div className="card-description">
                  Use the GPT store to boost productivity for small business.
                </div>
                <div className="card-actions">
                  <a
                    href={prompt2}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button"
                  >
                    <i className="material-symbols-outlined inline-icon">
                      picture_as_pdf
                    </i>{" "}
                    View
                  </a>
                </div>
              </div>
            </div>

            {/* Newsletter 6 */}
            <div className="newsletter-card">
              <div className="card-image-container">
                <a href={prompt1} target="_blank" rel="noopener noreferrer">
                  <img
                    className="prompt_cover"
                    src={require("../prompt/prompt1.png")}
                    alt="January 2024 newsletter cover"
                  />
                </a>
              </div>
              <div className="card-content">
                <div className="card-title">Generative AI in Marketing</div>
                <div className="card-tag">January 2024</div>
                <div className="card-description">
                  Learn the best AI tools for marketing professionals.
                </div>
                <div className="card-actions">
                  <a
                    href={prompt1}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button"
                  >
                    <i className="material-symbols-outlined inline-icon">
                      picture_as_pdf
                    </i>{" "}
                    View
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThePrompt;