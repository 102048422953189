import React from "react";
import { useNavigate } from "react-router-dom";

import '../styles.css';
import titleImage from '../images/frontpage1.webp';

const SuccessApplication = ({onClose}) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="modal">
                <div className="modal_card">
                    <h1 style={{ fontSize: "50px", fontFamily: "ProximaNova-Bold" }}>Application Submitted</h1>
                    <p style={{ fontSize: "20px", lineHeight: "1.5" }}>Thank you for submitting your application to AI Core. We will review your application and get back to you shortly.</p>
                    <button className="modal_close_button" onClick={onClose}>X</button>
                    <button type="button" className="rounded_button" onClick={() => navigate("/")} style={{ marginTop: "20px" }}>Return to Home</button>
                </div>
            </div>
        </>
    );

}

export default SuccessApplication;