import React, { useState, useEffect } from 'react';
import { useLocation, BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import axios from 'axios';

import Topbar from "./components/Topbar";
import TopbarCustomGPT from './components/TopbarCustomGPT';
import Portfolio from "./components/Portfolio";
import ThePrompt from "./components/ThePrompt";
import WorkWithUs from './components/WorkWithUs';
import SponsorUs from "./components/SponsorUs";
import RequestMeeting from "./components/RequestMeeting";
import Workshops from "./components/Workshops";
import Admin from './components/Admin';
import CMS from './components/CMS';
import ChatIcon from './components/ChatIcon';
import Footer from "./components/Footer";
import Chat from "./components/Chat";
import ChatWebapiGallery from './components/ChatWebapiGallery';
import Sidebar from './components/Sidebar';
import SuccessApplication from './components/SuccessApplication';
import Preview from './components/Preview';
import Home2 from './components/Home2';
import ScrollToTop from './components/ScrollToTop';
import Speaking from './components/Speaking';
import CustomGPTs from './components/CustomGPTs';
import CustomChat from './components/CustomChat';
import BarryBeeBensonWebPage from './components/webApiProjects/BarryBeeBenson/webPage';
import EmojiWebPage from "./components/webApiProjects/EmojiStoryBot/emojiWebPage";
import FinAssistanceWebPage from './components/webApiProjects/FinancialAssistant/webPage';  
import MultiPersonalities from './components/webApiProjects/OpenAIMultiplePersonalities/multiPersonalities';
import WildcatWelcome from './components/WildcatWelcome/WildcatWelcome';
import BrainCanvas from './components/WildcatWelcome/BrainCanvas';
import LandingPage from './components/WildcatWelcome/LandingPage';
import SendEmailtoUs from './components/SendEmailToUs';
import WebcamCapture from './components/webcamProject/WebcamCapture';
import VideoComparisonGallery from './components/VideoComparisonGallery/VideoComparisonGallery';
import ScavengerHunt from './components/ScavengerHunt/ScavengerHunt';
import VoiceToArt from './components/VoiceToArt/VoiceToArt';
import WebXRWorld from './components/WebXRWorld/WebXRWorld';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = { apiResponse: "" };
  }
  render() {
    return (
      <div className="app-container">
        <BrowserRouter>
          <Content />
        </BrowserRouter>
      </div>
    );
  }
}

const Content = () => {

  const [GPT, setGPT] = useState(JSON.parse(localStorage.getItem('GPT')) || "GPT-4o");
  const [threadID, setThreadID] = useState(localStorage.getItem('threadID') || null);
  const [netID, setNetID] = useState(localStorage.getItem('netID') || null);
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const savedTheme = localStorage.getItem('theme') || 'light';
  const [theme, setTheme] = useState(savedTheme);

  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState("");
  const [messagesInConversation, setMessagesInConversation] = useState([]);

  const location = useLocation();

  // reads query string with login data
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const netID = queryParams.get('netID');
    const token = queryParams.get('token');

    if (netID && token) {
      localStorage.setItem('netID', netID);
      localStorage.setItem('token', token);
      setNetID(localStorage.getItem('netID'))

      // Redirect to the home page
      window.location.href = '/';
    }
  }, [location]);

  // updates theme colors
  useEffect(() => {
    if (theme === "dark") {

      document.documentElement.style.setProperty('--chat000', 'white');
      document.documentElement.style.setProperty('--chat100', '#171717');
      document.documentElement.style.setProperty('--chat200', '#212121');
      document.documentElement.style.setProperty('--chat300', '#424242');
      document.documentElement.style.setProperty('--chat400', 'rgba(255, 255, 255, 0.05)');
      document.documentElement.style.setProperty('--chat500', '#2f2f2f');
      document.documentElement.style.setProperty('--chat900', 'black');
    } else {

      document.documentElement.style.setProperty('--chat000', 'black');
      document.documentElement.style.setProperty('--chat100', '#f9f9f9');
      document.documentElement.style.setProperty('--chat200', 'white');
      document.documentElement.style.setProperty('--chat300', 'rgba(0, 0, 0, 0.15)');
      document.documentElement.style.setProperty('--chat400', 'rgba(0, 0, 0, 0.05)');
      document.documentElement.style.setProperty('--chat500', 'white');
      document.documentElement.style.setProperty('--chat900', 'white');
    }
  }, [theme]);

  // fetches user's conversations from the database
  useEffect(() => {
    const fetchConversations = async () => {
    const token = localStorage.getItem('token');
    if (token && token !== "null") {
        try {
        const response = await axios({
          url: `${process.env.REACT_APP_BACKEND_URL}/routes/fetch-conversations`,
          method: 'GET',
          responseType: 'json',
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
          }
        });
        if (response.data) {
          setConversations(response.data.conversations);
        }

        } catch (error) {
        console.error('Error fetching conversations:', error);
        }
    } 
    };

    fetchConversations();
  }, [messagesInConversation]);

  return (
    <>
      <ScrollToTop/>
      {!window.location.pathname.match(/webapi-gallery\/.*/) && <Topbar GPT={GPT} setGPT={setGPT} threadID={threadID} setThreadID={setThreadID} netID={netID} setNetID={setNetID} setToken={setToken}/>}

      {window.location.pathname === "/chat" && <Sidebar netID={netID} setNetID={setNetID} theme={theme} setTheme={setTheme} conversations={conversations} setConversations={setConversations} selectedConversation={selectedConversation} setSelectedConversation={setSelectedConversation}/>}
      {window.location.pathname === "/custom-chat" && <Sidebar netID={netID} setNetID={setNetID} theme={theme} setTheme={setTheme} conversations={conversations} setConversations={setConversations} selectedConversation={selectedConversation} setSelectedConversation={setSelectedConversation}/>}

      <Routes>
        <Route path="/speaking" exact element={<Speaking/>}/>
        <Route path="/success-application" exact element={<SuccessApplication/>}/>
        <Route path="/" exact element={<Home2/>}/>
        <Route path="/home" exact element={<Home2/>}/>
        <Route path="/preview" exact element={<Preview/>}/>
        <Route path="/portfolio" exact element={<Portfolio/>}/>
        <Route path="/the-prompt" exact element={<ThePrompt/>}/>
        <Route path="/work-with-us" exact element={<WorkWithUs/>}/>
        <Route path="/sponsor-us" exact element={<SponsorUs/>}/>
        <Route path="/request-a-zoom" exact element={<RequestMeeting/>}/>
        <Route path="/workshops" exact element={<Workshops/>}/>
        <Route path="/admin" exact element={(netID) ? <Admin/> : <Navigate to="/"/>}/>
        <Route path="/cms" exact element={(netID) ? <CMS/> : <Navigate to="/"/>}/>
        <Route path="/chat" exact element={<Chat netID={netID} conversations={conversations} setConversations={setConversations} selectedConversation={selectedConversation} setSelectedConversation={setSelectedConversation} messagesInConversation={messagesInConversation} setMessagesInConversation={setMessagesInConversation} GPT={GPT} threadID={threadID} setThreadID={setThreadID} theme={theme} token={token}/>}/>
        <Route path="/customgpt" exact element={<CustomGPTs netID={netID} conversations={conversations} setConversations={setConversations} selectedConversation={selectedConversation} setSelectedConversation={setSelectedConversation} messagesInConversation={messagesInConversation} setMessagesInConversation={setMessagesInConversation} GPT={GPT} setGPT = {setGPT} threadID={threadID} setThreadID={setThreadID} theme={theme} token={token}/>}/>
        <Route path="/custom-chat" exact element={<CustomChat netID={netID} conversations={conversations} setConversations={setConversations} selectedConversation={selectedConversation} setSelectedConversation={setSelectedConversation} messagesInConversation={messagesInConversation} setMessagesInConversation={setMessagesInConversation} GPT={GPT} threadID={threadID} setThreadID={setThreadID} theme={theme} token={token}/>}/>
        <Route path="/webapi-gallery" exact element={<ChatWebapiGallery netID={netID} conversations={conversations} setConversations={setConversations} selectedConversation={selectedConversation} setSelectedConversation={setSelectedConversation} messagesInConversation={messagesInConversation} setMessagesInConversation={setMessagesInConversation} GPT={GPT} threadID={threadID} setThreadID={setThreadID} theme={theme} token={token}/>}/>
        <Route path="/webapi-gallery/barry-bee-benson" element={<BarryBeeBensonWebPage />} />
        <Route path="/webapi-gallery/emoji-story-bot" element={<EmojiWebPage />} />
        <Route path="/webapi-gallery/financial-assistant" element={<FinAssistanceWebPage />} />
        <Route path="/webapi-gallery/openai-multiple-personalities" element={<MultiPersonalities />} />
        <Route path="/wildcat-welcome" element={<WildcatWelcome />} />
        <Route path="/brain-scans" element={<BrainCanvas />} />
        <Route path="/send-email-to-us" element={<SendEmailtoUs />} />
        <Route path="/webcam" element={<WebcamCapture />} />
        <Route path="/videocomparison" element={<VideoComparisonGallery />} />
        {/* <Route path="/temp-welcome" element={<TempWelcome />} /> */}
        {/* <Route path="/temp-welcome2" element={<SelectionMenu />} /> */}
        <Route path="/landing-page" element={<LandingPage />} />
        <Route path="/scavenger-hunt" element={<ScavengerHunt />} />
        <Route path="/voice-to-art" element={<VoiceToArt />} />
        <Route path="/webxr-world" element={<WebXRWorld />} />


        
        <Route path="*" exact element={<Navigate to="/"/>}/>
      </Routes>
      {window.location.pathname !== "/chat" && window.location.pathname !== "/custom-chat" && <ChatIcon/>}

      {!window.location.pathname.match(/webapi-gallery\/.*/) && window.location.pathname !== "/chat" && window.location.pathname !== "/custom-chat" && <Footer/>}


      {/* {&& window.location.pathname !== "/chat" && <Footer/>} */}
    </>
  );
}

export default App;