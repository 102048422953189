import React from "react";
import { useNavigate } from "react-router-dom";

import '../styles.css';
import titleImage from '../images/frontpage1.webp';
import SuccessApplication from "./SuccessApplication";

const Speaking = () => {

    const navigate = useNavigate();
    const [showSuccess, setShowSuccess] = React.useState(false);
    
    return (
        <>
        <div className="animated_bg" style={{ backgroundImage: `url(${titleImage})`}}>
            <div className="animated_title">Speaking & Engagement</div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", padding: "50px" }}>
            <div className="speaking_block">
                <img style={{ borderRadius: "25px", width: "100%", minWidth: "300px", marginTop: "110px" }} src={require('../images/3.webp')} alt=""/>
            </div>
            <div className="speaking_block">
                <span style={{ fontSize: "50px", fontFamily: "ProximaNova-Bold" }}>Speaking and Engagements</span><br/><br/>

                <div style={{ lineHeight: "1.5", marginTop: "5px", marginBottom: "10px" }}>
                Explore the forefront of artificial intelligence for your organization with an AI Core custom speaking engagement or hands-on work session.  
                We can address crucial topics such as AI in education, public safety, ethics, labor, media, privacy, governance, and beyond, sharing with you insights, techniques and thoughts on policy as we acquire them at the University. 
                Whether you seek insights, strategy development, or implementation guidance, we're equipped to expedite your journey into the realm of AI!
                </div><br/>

                <button type="button" className="rounded_button" onClick={() => {navigate('/send-email-to-us')}}>Send us an Email</button>
            </div> 


        </div>

        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", marginTop: "50px",backgroundColor:"#edefeb", padding: "50px" }}>
            <div className="speaking_block1">
                <span style={{ fontSize: "50px", fontFamily: "ProximaNova-Bold" }}>Our Presentations</span><br/><br/>

                <div style={{ lineHeight: "1.5", marginTop: "5px", marginBottom: "10px", fontSize: "20px" }}>
                    We've presented to a wide range of academic, industry, and public sector partners to bring up-to-date understanding of AI. Whether you're a local business, community organization, a university department or lecturer, we are eager to share what we've recently learned and to get that knowledge out into your organization.
                </div><br/>
            </div>

            <div className="speaking_block2">
                <div style={{lineHeight: "2", borderRadius:"10px", backgroundColor:"white", minHeight:"300px", padding: "20px"}}>
                    <ul className = "coop_list">
                        <li>AZ Broadband Council</li>
                        <li>Arizona Small Business Association</li>
                        <li>Oro Valley Chamber of Commerce</li>
                        <li>University of Arizona Central Marketing & Communications</li>
                        <li>Southern Arizona Attractions Alliance</li>
                        <li>Arizona Historical Society</li>
                        <li>Tucson Public Sector Communicators</li>
                    </ul>
                </div>
            </div>
        </div>

        {/* <button type="button" className="rounded_button" onClick={() => {setShowSuccess(true)}} style={{ marginTop: "20px" }}>Return to Home</button>
        {showSuccess && <SuccessApplication onClose={() => setShowSuccess(false)}> 
            </SuccessApplication>} */}
        </>
    );
}

export default Speaking;