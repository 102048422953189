// ExperienceToArt.js
import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import './VoiceToArt.css';

function VoiceToArt() {
  const webcamRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [userExperience, setUserExperience] = useState(''); // User input for experience
  const [capturedImage, setCapturedImage] = useState(null);
  const [generatedImage, setGeneratedImage] = useState(null); // Image from DALL-E

  // Capture a photo from the webcam
  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
  };

  // Send the text and image to the backend
  const sendToBackend = async () => {
    if (!capturedImage || !userExperience.trim()) {
      alert("Please enter your experience and capture an image first.");
      return;
    }

    setLoading(true);
    const base64Image = capturedImage.split(',')[1]; // Remove data:image/jpeg;base64, part

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/routes/experience_to_art`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ image: base64Image, userExperience })
      });

      const data = await response.json();
      setGeneratedImage(data.message); // Store the generated image URL
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="experience-container">
      <header className="experience-header">
        <h1>Experience to Art</h1>
        <p>Describe your visit and capture an image to generate themed artwork!</p>
      </header>

      <div className="input-section">
        <textarea
          placeholder="Describe what you enjoyed the most..."
          value={userExperience}
          onChange={(e) => setUserExperience(e.target.value)}
          className="experience-textarea"
        />

        <div className="webcam-wrapper">
          <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" />
          <button onClick={capture} className="capture-btn">Capture Image</button>
        </div>
      </div>

      {capturedImage && (
        <div className="captured-preview">
          <h3>Your Captured Image</h3>
          <img src={capturedImage} alt="Captured" />
        </div>
      )}

      <button onClick={sendToBackend} disabled={loading} className="submit-btn">
        {loading ? "Processing..." : "Generate Art"}
      </button>

      {generatedImage && (
        <div className="generated-preview">
          <h3>Generated Artwork</h3>
          <img src={generatedImage} alt="Generated Art" />
        </div>
      )}
    </div>
  );
}

export default VoiceToArt;
