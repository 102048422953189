import React, { Suspense, useEffect, useState, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Preload, useGLTF } from '@react-three/drei';
import CanvasLoader from '../../Loader';

const gltfModels = ['fighter_jet/fighter_jet.glb'];

const FighterJetScans = ({ isMobile }) => {
  const gltfModel = useGLTF(gltfModels[0]);
  const jetRef = useRef();

  // Animation logic - Reversed Flight Path + Slower Speed
  useFrame(({ clock }) => {
    const t = clock.getElapsedTime() * 0.3; // Slower speed (0.3x of original)
    const radius = isMobile ? 5 : 8; // Flight radius
    const height = 2 + Math.sin(t * 1.5); // Smooth altitude variation
    const x = radius * Math.cos(-t); // Reversed motion X
    const z = radius * Math.sin(-t); // Reversed motion Z
    jetRef.current.position.set(x, height, z);
    jetRef.current.rotation.y = t; // Rotate jet in correct direction
  });

  return (
    <mesh ref={jetRef}>
      <hemisphereLight intensity={3} groundColor="blue" />
      <primitive 
        object={gltfModel.scene}
        scale={isMobile ? 2.5 : 3} 
        rotation={[0, Math.PI, 0]}
      />
    </mesh>
  );
};

const FighterJetCanvas = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);

    const handleMediaQueryChange = (e) => {
      setIsMobile(e.matches);
    };

    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => mediaQuery.removeEventListener('change', handleMediaQueryChange);
  }, []);

  return (
    <Canvas
      frameloop="always"
      shadows
      camera={{ position: [0, 5, 15], fov: 50 }}
      gl={{ preserveDrawingBuffer: true }}
      style={{ width: '100%', height: '100%' }}
    >
      <Suspense fallback={<CanvasLoader />}>
        <OrbitControls 
          enableZoom={true} 
          autoRotate 
          enablePan 
          minDistance={5}
          maxDistance={20}
          target={[0, 2, 0]} 
        />
        <FighterJetScans isMobile={isMobile} />
      </Suspense>
      <Preload all />
    </Canvas>
  );
};

export default FighterJetCanvas;
